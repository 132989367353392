.root {
  background: linear-gradient(to top, #051d41, #1e3b69);
}

.heading span {
  display: block;
}

.label {
  letter-spacing: 1px;
  max-width: calc(100% - 50px);
}

.largeLabel {
  line-height: 16.8px;
}

.smallLabel {
  font-size: 12px;
  line-height: 14.4px;
}

.sku {
  line-height: 14.4px;
}

.largeTitle span {
  font-size: 32px !important;
  line-height: 32px !important;
  font-weight: 800;
}

.smallTitle span {
  font-size: 22px !important;
  line-height: 22px !important;
  font-weight: 800;
}

.description {
  max-width: 400px;
}

.smallDescription {
  max-width: 318px;
}

.largeDescription span {
  font-size: 14px !important;
  line-height: 18px !important;
}

.smallDescription span {
  font-size: 12px !important;
  line-height: 16px !important;
}

.savePrice,
.shopNow {
  min-width: 100px;
}

.shopNow {
  --btn-size-lg-height: 48px;
}

.savePrice span {
  letter-spacing: 1px;
  display: inline-block;
}

.savePrice span:first-of-type {
  margin-right: 4px;
}

.sticker {
  border-width: 5px;
  height: 125px;
  width: 125px;
}

.stickerContent {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
}

.smallSavePrice span {
  font-size: 14px;
  line-height: 14px;
}

.salesPrice span:first-of-type {
  display: inline-block;
  margin-right: 6px;
}

.salesPrice span:last-of-type {
  display: inline-block;
  font-weight: 800;
}

.smallSalesPrice {
  line-height: 22px;
}

.largeSalesPrice span:first-of-type {
  font-size: 22px;
  line-height: 24px;
}

.largeSalesPrice span:last-of-type {
  font-size: 32px;
  line-height: 24px;
}

.smallSalesPrice span:first-of-type {
  font-size: 16px;
  line-height: 22px;
}

.smallSalesPrice span:last-of-type {
  font-size: 22px;
  line-height: 14px;
}

.smallDisclaimer {
  font-size: 12px;
  line-height: 14.4px;
  max-width: 168px;
}

.largeDisclaimer {
  line-height: 16.8px;
  max-width: 174px;
}

@media (--desktop) {
  .savePrice,
  .shopNow {
    min-width: 192px;
  }

  .shopNow {
    --btn-size-lg-height: 56px;
    --btn-size-lg-padding: 16px;
  }
}

@media (--tablet) {
  .disclaimer {
    font-size: 10px;
    line-height: 12px;
  }

  .largeLabel {
    font-size: 12px;
    line-height: 14.4px;
  }

  .largeTitle span {
    font-size: 22px !important;
    line-height: 22px !important;
    font-weight: 800;
  }

  .description {
    max-width: 318px;
  }

  .largeDescription span {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  .largeSavePrice span {
    font-size: 14px;
    line-height: 14px;
  }

  .largeSalesPrice {
    line-height: 22px;
  }

  .largeSalesPrice span:first-of-type {
    font-size: 16px;
    line-height: 22px;
  }

  .largeSalesPrice span:last-of-type {
    font-size: 22px;
    line-height: 14px;
  }

  .largeDisclaimer {
    font-size: 12px;
    line-height: 14.4px;
    max-width: 168px;
  }
}

@media (--mobile) {
  .label {
    font-size: 8px;
    line-height: 9.6px;
  }

  .sku {
    font-size: 10px;
    line-height: 12px;
  }

  .heading span {
    font-size: 18px !important;
    line-height: 22px !important;
  }

  .disclaimer {
    font-size: 10px;
    line-height: 12px;
    max-width: 141px;
  }

  .salesPrice span:first-of-type {
    font-size: 12px;
    line-height: 24px;
  }

  .salesPrice span:last-of-type {
    font-size: 14px;
    line-height: 10px;
  }

  .savePrice span {
    font-size: 10px;
    line-height: 14px;
  }

  .sticker {
    border-width: 3px;
    height: 100px;
    width: 100px;
  }
}
